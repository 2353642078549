<template>
	<div class="vld-parent">
		<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">            
				<Toast/>
                <Breadcrumb :model="rastromenu" />
				<div class="p-fluid p-formgrid p-grid p-mt-2">
					<div class="p-field p-col">
						<label>Fecha</label>
						<Calendar id="fecha" v-model="reclamo.fecha" :showIcon="true" dateFormat="dd-MM-yy"/>
					</div>
					<div class="p-field p-col">
						<label class="p-text-bold" for="numero_ref"># Referencia</label>
						<InputText disabled v-model.trim="reclamo.numero_ref"/>
					</div>					
				</div>		
				<div class="p-fluid p-formgrid p-grid p-mt-2">
					<div class="p-field p-col">
						<label for="tipo">Clasificación</label>
						<Dropdown v-model="reclamo.id_reclamo_tipo" :options="lkpTipos" optionLabel="tipo" optionValue="id_reclamo_tipo" placeholder="Seleccionar..." />
					</div>									
					<div class="p-field p-col">
						<label for="estado">Estado</label>
						<Dropdown :disabled="esNuevo" v-model="reclamo.id_reclamo_estado" :options="lkpEstados" optionLabel="estado" optionValue="id_reclamo_estado" placeholder="Seleccionar..." />
					</div>	
				</div>						
				<div class="p-field">
					<label for="estado">Autor</label><span class="p-error">*</span>
					<div class="p-fluid p-inputgroup">
						<InputText id="autor" disabled :key="entidadUpdate" v-model="reclamo.entidad" :class="{'p-invalid': v$.reclamo.entidad.$error}"/>						
						<Button icon="pi pi-search" @click="buscarEntidadDlg=true"/>													
					</div>				
					<div class="p-error" v-if="v$.reclamo.entidad.$error">Este campo es obligatorio</div>													
				</div>															
				<div class="p-fluid p-field">
					<div v-if="lkpInmuebles.length > 0" >
						<label for="estado">Inmueble</label>					
						<Dropdown :key="entidadUpdate" :disabled="lkpInmuebles.length == 1" v-model="reclamo.id_inmueble" :options="lkpInmuebles" optionLabel="display" optionValue="id_inmueble" placeholder="Seleccionar..." />
					</div>
				</div>																		
				<TabView @tab-click="onTabClick($event)">
					<TabPanel>
						<template #header>
						<i class="pi pi-globe p-m-1"></i>
						<span>Generales</span>
						</template>
						<div class="p-fluid">												
							<div class="p-field">
								<label for="asunto">Asunto</label><span class="p-error">*</span>
								<InputText v-model="v$.reclamo.asunto.$model" :class="{'p-invalid': v$.reclamo.asunto.$error}"/>
								<div class="p-error" v-if="v$.reclamo.asunto.$error">Este campo es obligatorio</div>
							</div>															
							<div class="p-field">
								<label for="descripcion">Descripción</label><span class="p-error">*</span>
								<Textarea v-model="v$.reclamo.descripcion.$model" rows="5" cols="30" :class="{'p-invalid': v$.reclamo.descripcion.$error}"/>
								<div class="p-error" v-if="v$.reclamo.descripcion.$error">Este campo es obligatorio</div>
							</div>																															
						</div>	
					</TabPanel>
					<TabPanel :disabled="esNuevo">
						<template #header>
							<i class="pi pi-exclamation-circle p-m-1"></i>
							<span>Resolución</span>
						</template>
						<div class="p-fluid">												
							<div class="p-field p-col">
								<label for="fecha_resolucion">Fecha Resolución</label>
								<Calendar id="fecha_resolucion" v-model="reclamo.fecha_resolucion" :showIcon="true" dateFormat="dd-MM-yy"/>								
							</div>											
							<div class="p-field p-col">
								<label for="resolucion">Resolución</label>
								<Textarea v-model="reclamo.resolucion" rows="5" cols="30" />
							</div>									
						</div>									
					</TabPanel>
					<TabPanel :disabled="esNuevo">
						<template #header>
							<i class="pi pi-envelope p-m-1"></i>
							<span>Suscripciones</span>
						</template>
						<Toolbar class="p-mb-4">
							<template v-slot:right>
								<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="nuevoSuscriptor"/>				
							</template>
						</Toolbar>							
						<DataTable ref="suscriptores" :value="suscriptores" class="p-datatable-sm" dataKey="id_reclamo_suscriptor" :paginator="true" :rows="5">
							<Column field="email" header="email" headerStyle="width: 30%"></Column>											
							<Column>
								<template #body="slotProps">
									<Button icon="pi pi-times" class="p-button-rounded p-button-outlined p-button-secondary p-mr-1" @click="confirmDeleteSuscriptor
									(slotProps.data)" />									
								</template>
							</Column>							
						</DataTable>
					</TabPanel>									
					<TabPanel :disabled="esNuevo">
					<template #header>
						<i class="pi pi-list p-m-1"></i>
						<span>Historial</span>
					</template>
					<DataTable ref="historial" :value="historial" class="p-datatable-sm" dataKey="id_reclamo_log" :paginator="true" :rows="5">
						<Column field="fecha_creacion" header="Fecha" headerStyle="width: 15%">
							<template #body="slotProps">
								{{slotProps.data.fecha_creacion}}
							</template>
						</Column>
						<Column field="evento" header="Evento" headerStyle="width: 45%"></Column>
						<Column field="estado" header="Estado" headerStyle="width: 15%"></Column>
						<Column field="usuario" header="Usuario" headerStyle="width: 25%"></Column>													
					</DataTable>
					</TabPanel>					
				</TabView>
				<div v-if="!esNuevo" class="p-fluid p-formgrid p-grid p-mt-2">
					<div class="p-field p-col">
						<label for="Creado Por">Creado Por</label>
						<InputText disabled v-model="reclamo.usuario" />
					</div>							
					<div class="p-field p-col">
						<label for="tel_celular">Creación</label>
						<InputText disabled v-model="reclamo.fecha_creacion" />
					</div>
				</div>
				<Button label="Guardar" icon="pi pi-check" class="p-button" @click="save" :disabled="v$.reclamo.$invalid"/>
			</div>			
		</div>
		<MdsBuscarEntidad :mostrar="buscarEntidadDlg" @cancelar="buscarEntidadDlg=false" @seleccionar="seleccionEntidad" />
	
		<Dialog v-model:visible="deleteSuscriptorDlg" :style="{width: '450px'}" header="Confirmar" :modal="true">
			<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span>Está seguro que desea remover este email de la lista de suscriptores?<br><b>[{{ suscriptor.email }}]</b></span>
			</div>
			<template #footer>
				<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteSuscriptorDlg = false"/>
				<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="desuscribir" />
			</template>
		</Dialog>		

        <Dialog header="Agregar nuevo email para envio de notificaciones" v-model:visible="nuevoSuscriptorDlg" :style="{width: '35vw'}" :modal="true" @hide="v$.nuevoSuscriptorEmail.$reset()">			
			<div class="p-fluid p-m-2">
				<InputText v-model.trim="v$.nuevoSuscriptorEmail.$model" id="email2" placeholder="email" type="email" aria-describedby="username2-help" :class="{'p-invalid': v$.nuevoSuscriptorEmail.$error}" />
				<div class="p-error" v-if="v$.nuevoSuscriptorEmail.email.$invalid">La dirección de email no es válida!</div>
			</div>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" @click="nuevoSuscriptorDlg=false" class="p-button-text"/>
                <Button :disabled="v$.nuevoSuscriptorEmail.$invalid" label="Aceptar" icon="pi pi-check" @click="agregarSuscriptor" />
            </template>
        </Dialog>
	</div>

</template>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import useVuelidate from  '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import MdsService from '../../service/mds.service';
import MdsBuscarEntidad from '../../components/MdsBuscarEntidad.vue';

 export default {	 
	setup() { 
		return { v$: useVuelidate() };
	},
	validations () {
		return {
			nuevoSuscriptorEmail: {required, email},
			reclamo: {
				asunto: {required},
				descripcion : {required},
				entidad: {required}
			}			
		}
	},	
	data() {
		return {
			id_reclamo: this.id,
			complejo: this.$store.state.auth.currentAppCtx.complejo,
			lkpTipos: this.$store.state.auth.currentAppCtx.reclamos_tipo,
			lkpEstados: this.$store.state.auth.currentAppCtx.reclamos_estado,
			lkpInmuebles: [],
			loading: false,
			fullPageLoading: false,
			buscarEntidadDlg: false,
			entidadUpdate: 0,			
			rastromenu: [
				{label: 'Reclamos', to: '/mdsreclamos'}
			],   
			esNuevo: true,         
			reclamo: {
                id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
                id_entidad: null,
				entidad: null,
                id_inmueble: "",
                asunto: "",
                descripcion: "",
                resolucion: "",
                usuario: this.$store.state.auth.user.logonname,
                fecha: new Date(),
                fecha_resolucion: null,
                fecha_creacion: new Date(),
                id_reclamo: 0,
                id_reclamo_estado: 1,
                id_reclamo_tipo: this.$store.state.auth.currentAppCtx.reclamos_tipo[0].id_reclamo_tipo,
                numero_ref: null
			},
			historial: [],		
			historialCargado: false,
			suscriptores: [],		
			suscriptoresCargado: false,
			suscriptor: null,
			nuevoSuscriptorEmail: null,
			nuevoSuscriptorDlg: false,
			deleteSuscriptorDlg: false			
		}
	},
	components: {
		Loading, MdsBuscarEntidad
	},
	props: {
		id: {
			type: String,
			required: true,
			default: ''
			}			
	},		
	created(){
		if(this.id != "")
		{
			this.fetchData();
		} else {
			this.openNew();
		}
	},
	methods: {
		onTabClick(event)
		{
			if(event.index == 2) {
				this.getSuscriptores();
			} else if(event.index == 3){
				this.getHistorial();
			} 
		},	
		confirmDeleteSuscriptor(Suscriptor){
			this.suscriptor = Suscriptor;
			this.deleteSuscriptorDlg = true;			
		},
		nuevoSuscriptor () {
			this.nuevoSuscriptorEmail = '';
			this.nuevoSuscriptorDlg =true;			
		},		
		agregarSuscriptor()
		{
			this.nuevoSuscriptorDlg = false;
			this.loading = true;			
			
			MdsService.registrarSuscriptor(this.$store.state.auth.currentAppCtx, this.reclamo.id_reclamo, this.nuevoSuscriptorEmail).then(data => {
				this.loading = false;
				let nuevoSuscriptor = {
						id_reclamo_suscriptor: data.id_reclamo_suscriptor, 
						id_reclamo: this.id_reclamo,
						email: this.nuevoSuscriptorEmail
				};

				this.suscriptores.push(nuevoSuscriptor);

				this.nuevoSuscriptorEmail = null;
				this.suscriptoresCargado = true;
				this.historialCargado = false;
			}).catch(
					data => {
						this.loading = false;
						this.nuevoSuscriptorEmail = null;
						this.suscriptoresCargado = true;
						this.historialCargado = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
					}
			);
		},
		desuscribir()
		{
			this.deleteSuscriptorDlg = false;	
			
			this.loading = true;
			
			MdsService.removerSuscriptor(this.$store.state.auth.currentAppCtx, this.suscriptor.id_reclamo_suscriptor).then(()=>{
				this.loading = false;
				this.suscriptores  = this.suscriptores.filter(val => val.id_reclamo_suscriptor !== this.suscriptor.id_reclamo_suscriptor);
				this.suscriptor = [];
			}).catch(
					data => {
						this.suscriptor = [];
						this.historialCargado = false;
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
					}
				);
		},
		getSuscriptores()
		{
			if(this.suscriptoresCargado){
				return;
			}

			this.loading = true;			
			MdsService.getReclamoSuscriptores(this.$store.state.auth.currentAppCtx, this.reclamo.id_reclamo).then(data => {
				this.loading = false;
				this.suscriptores = data;
				this.suscriptoresCargado = true;
			}).catch(
					data => {
						this.loading = false;
						this.suscriptores = [];
						this.suscriptoresCargado = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
					}
			);
		},	
		getHistorial()
		{
			if(this.historialCargado){
				return;
			}

			this.loading = true;			
			MdsService.getReclamoHistorial(this.$store.state.auth.currentAppCtx, this.reclamo.id_reclamo).then(data => {
				this.loading = false;
				this.historial = data;
				this.historialCargado = true;
			}).catch(
					data => {
						this.loading = false;
						this.historial = [];
						this.historialCargado = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
					}
			);
		},
		openNew() {
			this.reclamo = {
				id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
				entidad: null,
				id_entidad: "",
				id_inmueble: "",
				asunto: null,
				descripcion: null,
				resolucion: null,
				usuario: this.$store.state.auth.user.logonname,
				fecha: new Date(),
				fecha_resolucion: null,
				fecha_creacion: new Date(),
				id_reclamo: 0,
				id_reclamo_estado: 1,
				id_reclamo_tipo: this.$store.state.auth.currentAppCtx.reclamos_tipo[0].id_reclamo_tipo,
				numero_ref: null			
			}; 

		},		
		resetReclamo()
		{
			console.log('Reset reclamo!');
			this.v$.reclamo.entidad.$reset();
		},
		save() {									
			this.loading = true;
			MdsService.registrarReclamo(this.$store.state.auth.currentAppCtx, this.reclamo).then(data => {
				this.loading = false;
				this.reclamo.id_reclamo = data.id_reclamo;
				this.reclamo.numero_ref = data.numero_ref;
				this.historialCargado = false;
				this.esNuevo = false;
				this.$toast.add({severity:'success', summary: 'Exito', detail: 'Los cambios han sido guardados con éxito!', life: 3000});									
			}).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);
		},
		seleccionEntidad(Entidad)
		{		
			this.buscarEntidadDlg = false;									
			this.reclamo.id_entidad = Entidad.id_entidad;
			this.reclamo.entidad = Entidad.contacto;		
			this.entidadUpdate++;

			this.loading = true;
			MdsService.getInmueblesXEntidad(this.$store.state.auth.currentAppCtx, this.reclamo.id_entidad).then(data => {				
				this.loading = false;
				this.lkpInmuebles = data;

				if(this.lkpInmuebles.length > 0)
				{
					this.reclamo.id_inmueble = this.lkpInmuebles[0].id_inmueble;
				}

				this.entidadUpdate++;
			}
			).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)			
		},
		fetchData () {
			this.error = this.post = null;
			this.reclamo.id_reclamo = this.id;
			
			let filtro = {
				id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
				id: this.reclamo.id_reclamo
			};

			this.loading = true;			
			MdsService.getReclamos(this.$store.state.auth.currentAppCtx, filtro).then(data => {				
				this.loading = false;
				this.reclamo = data;
				this.esNuevo = false;

				if(this.reclamo.id_reclamo_estado > 1){
					this.lkpEstados = this.$store.state.auth.currentAppCtx.reclamos_estado.filter(value => {return value.id_reclamo_estado != 1});
				}

				let entidad = {id_entidad: this.reclamo.id_entidad, contacto: this.reclamo.entidad};

				this.seleccionEntidad(entidad);				
			}
			).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		}			
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
